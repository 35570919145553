<template>
  <div>
    <b-card>
      <div class="row">
        <div class="col">
          <h4>Scope</h4>
          <div class="demo-inline-spacing mb-2">
            <b-form-radio
              v-model="view_select"
              name="some-radios10"
              value="single_product"
              class="mt-0"
            >
              Product
            </b-form-radio>
            <b-form-radio
              v-model="view_select"
              name="some-radios10"
              value="category"
              class="mt-0"
            >
              Category
            </b-form-radio>
            <b-form-radio
              v-model="view_select"
              name="some-radios10"
              value="brand"
              class="mt-0"
            >
              Brand
            </b-form-radio>
            <b-form-radio
              v-model="view_select"
              name="some-radios10"
              value="all"
              class="mt-0"
            >
              ทั้งหมด
            </b-form-radio>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-8">
          <div class="demo-inline-spacing mb-2">
            <b-form-radio
              v-model="tab_select"
              name="some-radios9"
              value="all"
              class="mt-0"
            >
              ทั้งหมด
            </b-form-radio>
            <b-form-radio
              v-model="tab_select"
              name="some-radios9"
              value="all-no-accessories"
              class="mt-0"
            >
              กล้อง+เลนส์ ไม่รวม accessories
            </b-form-radio>
            <b-form-radio
              v-model="tab_select"
              name="some-radios9"
              value="category"
              class="mt-0"
            >
              Category
            </b-form-radio>
            <!-- <b-form-radio
              v-model="tab_select"
              name="some-radios9"
              value="brand"
              class="mt-0"
            >
              Brand
            </b-form-radio> -->
          </div>

          <vue-autosuggest
            :suggestions="[{ data: formattedCategoriesOptions }]"
            :input-props="{
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'ค้นหาอุปกรณ์ที่ให้ไปด้วย',
            }"
            @selected="selectHandler"
            @input="updateSearchQuery"
            class="mb-2"
          >
            <template slot-scope="{ suggestion }">
              <span class="my-suggestion-item">{{ suggestion.item.name }}</span>
            </template>
          </vue-autosuggest>
        </div>
        <div class="col-md-4">
          <b-form-group label="วันที่">
            <date-picker-range @date_picked="date_picked"></date-picker-range>
          </b-form-group>
        </div>
      </div>
      <b-button variant="success" @click="load_data">Search</b-button>
    </b-card>
  </div>
</template>
<script>
import {
  BButton,
  BCard,
  BFormRadio,
  BFormSelect,
  BListGroup,
  BFormGroup,
} from "bootstrap-vue";
import DatePickerRange from "../../account/component/DatepickerRange.vue";
import product_categories from "@/assets/product_categories_db.json";
import { VueAutosuggest } from "vue-autosuggest";

export default {
  components: {
    BButton,
    BListGroup,
    BCard,
    BFormRadio,
    DatePickerRange,
    BFormSelect,
    product_categories,
    VueAutosuggest,
    BFormGroup,
  },
  data() {
    return {
      form: {},
      searchQuery: "",
      product_categories_options: product_categories,
      tab_select: "category",
      view_select: "single_product",
      categories_id: null,
    };
  },
  computed: {
    formattedCategoriesOptions() {
      return this.product_categories_options
        .map((x) => {
          const z = {};
          z.label = x.name;
          z.name = x.name;
          return z;
        })
        .sort((a, b) => {
          return a.sort_id && b.sort_id
            ? a.sort_id.localeCompare(b.sort_id)
            : 0;
        })
        .filter((item) =>
          item.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        ); // Filter based on searchQuery
    },
  },
  methods: {
    date_picked(x) {
      this.form.start_date = x.start;
      this.form.end_date = x.end;
    },
    selectHandler(categories) {
      this.form.categories = categories.item.name;
      console.log("categories", categories);
    },
    updateSearchQuery(value) {
      this.searchQuery = value;
    },
    load_data() {
      this.form.tab_select = this.tab_select;
      this.form.view_select = this.view_select;

      if (this.form.view_select === "category") {
        this.$http({
          method: "POST",
          url: `/stats/category-bar`,
          data: this.form,
        }).then((x) => {
          this.$emit("pie_data", x.data.data);
        });
      }

      if (this.form.view_select === "single_product") {
        this.$http({
          method: "POST",
          url: `/stats/booking-load-bar`,
          data: this.form,
        }).then((x) => {
          this.$emit("filterbar_data", x.data.data);
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-autosuggest.scss";
</style>
